import $ from 'jquery';

var pages = parseInt($('.btn--load-more').attr('data-last-page'));
if (pages < 2 ) {
  $('.btn--load-more').hide();
}

if ($('.material-block .btn--load-more').attr('data-post-type') == 'material') {
  $('.material-block .filter__checkbox').on('change', function () {
    if ($(this).attr('data-material-term-id') == 'all') {
      if (this.checked) {
        $.each($('.material-block .filter__checkbox'), function () {
          if ($(this).attr('data-material-term-id') != 'all') {
            $(this).prop('checked', true);
          }
        });
      } else {
        $.each($('.material-block .filter__checkbox'), function () {
          if ($(this).attr('data-material-term-id') != 'all') {
            $(this).prop('checked', false);
          }
        });
      }
    } else {
      if ($('.material-block .filter__checkbox:checked').length == $('.filter__checkbox').length - 1 && (!$('.material-block .filter__checkbox[data-material-term-id="all"]').is(':checked'))) {
        $('.material-block .filter__checkbox[data-material-term-id="all"]').prop('checked', true);
      } else {
        $('.material-block .filter__checkbox[data-material-term-id="all"]').prop('checked', false);
      }
    }
    $('.material-block .btn--load-more').attr('data-page', 0);
    $('.material-block .btn--load-more').trigger('click');
  });
}


if ($('.showcase .btn--load-more').attr('data-post-type') == 'product_and_service') {
  $('.showcase .filter__checkbox').on('change', function () {
    $('.showcase .btn--load-more').attr('data-page', 0);
    $('.showcase .btn--load-more').trigger('click');
  });
}

$('.showcase .filter__clear-button').on('click', function () {
  $.each($('.showcase .filter__checkbox'), function () {
    $(this).prop('checked', false);
  });
  $('.showcase .btn--load-more').attr('data-page', 0);
  $('.showcase .btn--load-more').trigger('click');

  if ($('.showcase .filter__checkbox:checked').length == 0) {
    $('.showcase .filter__clear-button').addClass('disable');
  }
});

if ($('.showcase .filter__checkbox:checked').length == 0) {
  $('.showcase .filter__clear-button').addClass('disable');
}

$('.showcase .filter__checkbox').on('change', function () {
  if ($('.showcase .filter__checkbox:checked').length > 0) {
    $('.showcase .filter__clear-button').removeClass('disable');
  } else {
    $('.showcase .filter__clear-button').addClass('disable');
  }
});

$('.btn--load-more').on('click', function (e) {
  e.preventDefault();
  var button = $(this);
  var postType = button.attr('data-post-type');
  var currentPage = parseInt(button.attr('data-page'));
  var lastPage = parseInt(button.attr('data-last-page'));
  var nextPage = currentPage + 1;
  button.addClass("btn--loading");
  $('.filter__checkbox').addClass('data-loading');
  var nonce = button.attr('data-nonce');

  var data = {
    action: 'loadmore',
    nextPage: nextPage,
    postType: postType,
    nonce: nonce,
    materialCategory: [],
    productCategory: [],
    propertyCategory: []
  }

  if (!$('.material-block .filter__checkbox[data-material-term-id=all]:checked').length) {
    $.each($('.material-block .filter__checkbox:checked'), function () {
      data.materialCategory.push($(this).attr('data-material-term-id'));
    });
  }

  if ($('.filter__col--product .filter__checkbox:checked').length) {
    $.each($('.filter__col--product .filter__checkbox:checked'), function () {
      data.productCategory.push($(this).attr('data-product-and-sevice-term-id'));
    });
  }

  if ($('.filter__col--property .filter__checkbox:checked').length) {
    $.each($('.filter__col--property .filter__checkbox:checked'), function () {
      data.propertyCategory.push($(this).attr('data-product-and-sevice-property-term-id'));
    });
  }

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'POST',
    data: data,
    beforeSend: function () {
    },
    success: function (response) {
      if (response) {
        if (postType = 'news') {
          $(response.item_html).appendTo('.article-block__row');
        }
        if (postType = 'material') {
          if (nextPage == 1) {
            $('.material-block__row').html(response.item_html);
          }
          else {
            $(response.item_html).appendTo('.material-block__row');
          }
          $('.btn--load-more').attr('data-filter-last-page', response.last_page);
        }
        if (postType = 'product_and_service') {
          if (nextPage == 1) {
            $('.showcase__row').html(response.item_html);
          }
          else {
            $(response.item_html).appendTo('.showcase__row');
          }
          $('.btn--load-more').attr('data-filter-last-page', response.last_page);
        }

        $('.filter__result-counter').html(response.found_posts);
      }
      if (nextPage >= lastPage || nextPage >= response.last_page) {
        button.hide();
      } else {
        button.attr('data-page', nextPage).show();
      }
      button.removeClass("btn--loading");
      $('.filter__checkbox').removeClass('data-loading');
    },
    error: function (error) {
      console.log(error);
    }
  })
})
