import $ from 'jquery';

$(function () {
  function buttonUp() {
    var value = $('.search-bar__input').val();
    value = $.trim(value).length;
    if (value !== 0) {
      $('.search-bar__button').css('z-index', '99');
    } else {
      $('.search-bar__input').val('');
      $('.search-bar__button').css('z-index', '-999');
    }
  }

  $('.search-bar').on('keyup', '.search-bar__input', function () {
    buttonUp();
  });
});

$(function () {
  var submitIcon = $('.search-bar__icon');
  var submitInput = $('.search-bar__input');
  var searchBox = $('.search-bar');
  var header = $('.page-header');
  var isOpen = false;

  $(document).on('click', function () {
    if (isOpen == true) {
      submitInput.val('');
      $('.search-bar__button').css('z-index', '-999');
      submitIcon.on('click');
    }
  });

  submitIcon.on('mouseup', function () {
    return false;
  });

  searchBox.on('mouseup', function () {
    return false;
  });

  submitIcon.on('click', function () {
    if (isOpen == false) {
      searchBox.addClass('search-bar--active');
      header.addClass('overlay-active');
      isOpen = true;
    } else {
      searchBox.removeClass('search-bar--active');
      header.removeClass('overlay-active');
      isOpen = false;
    }
  });
});
