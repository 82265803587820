import $, { contains } from 'jquery';
var Flickity = require('flickity');
require('flickity-imagesloaded');

Flickity.createMethods.push('_createPrevNextCells')
Flickity.prototype._createPrevNextCells = function () {
  this.on('select', this.setPrevNextCells)
}
Flickity.prototype.setPrevNextCells = function () {
  // remove classes
  changeSlideClasses(this.previousOfpreviousSlide, 'remove', 'is-previous-of-previous')
  changeSlideClasses(this.previousSlide, 'remove', 'is-previous')
  changeSlideClasses(this.nextSlide, 'remove', 'is-next')

  // set slides
  if (this.selectedIndex - 1 < 0) {
    this.previousSlide = this.slides[this.slides.length - 1]
  }
  else {
    this.previousSlide = this.slides[this.selectedIndex - 1]
  }
  if (this.selectedIndex + 1 === this.slides.length) {
    this.nextSlide = this.slides[0]
  }
  else {
    this.nextSlide = this.slides[this.selectedIndex + 1]
  }

  // add classes
  changeSlideClasses(this.previousSlide, 'add', 'is-previous')
  changeSlideClasses(this.nextSlide, 'add', 'is-next')
}
function changeSlideClasses(slide, method, className) {
  if (!slide) {
    return
  }
  slide.getCellElements().forEach(function (cellElem) {
    cellElem.classList[method](className)
  })
}


if ($('.js-carousel').length) {
  $.each($('.js-carousel'), function () {
    var carousel = new Flickity($(this)[0], {
      // options...
      groupCells: false,
      adaptiveHeight: true,
      prevNextButtons: false,
      setGallerySize: true,
      pageDots: false,
      imagesLoaded: true,
      cellAlign: 'center',
      contain: true
    });

    var carouselWrap = $(this).parents('.carousel-block');
    // currect slide and total slide coutner
    var currentSlide = carouselWrap.find('.js-carousel-current-slide');
    var totalSlide = carouselWrap.find('.js-carousel-total-slide');

    function updateStatus() {
      var slideNumber = carousel.selectedIndex + 1;
      totalSlide.text(carousel.slides.length);
      currentSlide.text(slideNumber);
    }

    updateStatus();
    // next, previous functionality
    function controlButton() {
      // previous
      carouselWrap.on('click', '.js-carousel-button-previous', function () {
        carousel.previous();
      });
      // next
      carouselWrap.on('click', '.js-carousel-button-next',  function () {
        carousel.next();
      });
    }

    controlButton();

    carousel.on('select', updateStatus, controlButton);
  });
}


if ($('.js-main-carousel').length) {
  $.each($('.js-main-carousel'), function () {
    var mainCarousel = new Flickity($(this)[0], {
      // options...
      groupCells: false,
      adaptiveHeight: true,
      prevNextButtons: false,
      setGallerySize: true,
      pageDots: false,
      imagesLoaded: true,
      cellAlign: 'left',
      contain: true
    });

    var carouselWrap = $(this).parents('.main-content__carousel-wrap');
    // currect slide and total slide coutner
    var currentSlide = carouselWrap.find('.js-main-carousel-current-slide');
    var totalSlide= carouselWrap.find('.js-main-carousel-total-slide');

    function updateStatus() {
      var slideNumber = mainCarousel.selectedIndex + 1;
      totalSlide.text(mainCarousel.slides.length);
      currentSlide.text(slideNumber);
    }

    updateStatus();
    // next, previous functionality
    function controlButton() {
      // previous
      carouselWrap.on('click', '.js-main-carousel-button-previous', function () {
        mainCarousel.previous();
      });
      // next
      carouselWrap.on('click', '.js-main-carousel-button-next',  function () {
        mainCarousel.next();
      });
    }

    controlButton();

    mainCarousel.on('select', updateStatus, controlButton);
  });
}


if ($('.js-testimonial-carousel').length) {
  $.each($('.js-testimonial-carousel'), function () {
    var testimonialCarousel = new Flickity($(this)[0], {
      // options...
      groupCells: false,
      adaptiveHeight: true,
      prevNextButtons: false,
      setGallerySize: true,
      pageDots: false,
      imagesLoaded: true,
      cellAlign: 'left',
      contain: true
    });

    var carouselWrap = $(this).parents('.testimonial-block__container');
    // currect slide and total slide coutner
    var currentSlide = carouselWrap.find('.js-testimonial-carousel-current-slide');
    var totalSlide = carouselWrap.find('.js-testimonial-carousel-total-slide');

    function updateStatus() {
      var slideNumber = testimonialCarousel.selectedIndex + 1;
      totalSlide.text(testimonialCarousel.slides.length);
      currentSlide.text(slideNumber);
    }

    if(testimonialCarousel.slides.length < 4) {
      $('.carousel-controller--testimonial-carousel').addClass('js-hidden');
    }

    updateStatus();
    // next, previous functionality
    function controlButton() {
      // previous
      carouselWrap.on('click', '.js-testimonial-carousel-button-previous', function () {
        testimonialCarousel.previous();
      });
      // next
      carouselWrap.on('click', '.js-testimonial-carousel-button-next',  function () {
        testimonialCarousel.next();
      });
    }

    controlButton();

    testimonialCarousel.on('select', updateStatus, controlButton);
  });
}
