import Cookies from 'js-cookie';
import $ from 'jquery';

var cookieBar = $('.site-cookie');
var cookieAcceptButton = $('#accept-cookies');

if (Cookies.get('srt_site_cookie')) {
  cookieBar.hide();
} else {
  cookieBar.slideToggle();
}

// when user agree on cookie bar
cookieAcceptButton.on('click', cookieAcceptButton, function () {
  Cookies.set('srt_site_cookie', 1, { expires: 365, path: '/' });
  if (Cookies.get('srt_site_cookie')) {
    $('.site-cookie').hide();
  }
});

// cookies for popup
var popupCloseButton = $('.js-popup-cookies-btn');

if (Cookies.get('srt_popup_cookie')) {
  if ($('body').hasClass('popup-active')) {
    $('body').removeClass('popup-active');
  }
} else {
  $('body').addClass('popup-active');
}

// when user agree on cookie bar
popupCloseButton.on('click', popupCloseButton, function () {
  Cookies.set('srt_popup_cookie', 1, { expires: 365, path: '/' });
  if (Cookies.get('srt_popup_cookie')) {
    $('body').removeClass('popup-active');
  }
});
