import $ from 'jquery';

// dropdown menu
$('.menu').on('click', '.menu__dropdown-opener', function () {
  if ($(window).width() < 1280 || $('html').hasClass('touch')) {
    if ($(this).next('ul').is(':visible')) {
      $(this).next('ul').slideUp('fast');
      $(this).removeClass('active');
      $(this).parent().addClass('active');
    } else {
      $(this).closest('ul').children('.menu-item-has-children').children('.active').next('ul').slideUp('fast');
      $(this).closest('ul').children('.menu-item-has-children').children('.active').removeClass('active');
      $(this).next().slideToggle('fast');
      $(this).addClass('active');
      $(this).parent().addClass('active');
    }
  }
});

// Lavalamp
var el, leftPos, newWidth,
  menu = $('.menu');

menu.append('<li id="lavalamp"></li>');
var lavalamp = $('#lavalamp');

// If there's an active menu
var parentActive = $('.menu > .menu-item.current-menu-item');
var childActive = $('.menu > li > ul > .menu-item.current-menu-item');

// If there's an active child
if (parentActive.length > 0) {
  lavalamp.css('left', parentActive.position().left + 11);
  lavalamp.width(parentActive.width() - 32);
}

if (childActive.length > 0) {
  lavalamp.css('left', childActive.closest('.current-menu-parent').position().left + 11);
  lavalamp.width(childActive.closest('.current-menu-parent').width() - 32);
}

if(parentActive.length < 1 && childActive.length < 1){
  lavalamp.css('left', '-34px');
  lavalamp.width(-30);
}

lavalamp.data('origLeft', lavalamp.position().left);
lavalamp.data('origWidth', lavalamp.width());

$('.menu > li').hover(function () {
  el = $(this);
  leftPos = el.position().left + 11;
  newWidth = el.width();
  lavalamp.stop().animate({
    left: leftPos,
    width: newWidth
  });
}, function () {
  lavalamp.stop().animate({
    left: lavalamp.data('origLeft'),
    width: lavalamp.data('origWidth') + 32
  });

});
